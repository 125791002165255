<script setup lang="ts">
import { CmsElementProductSlider } from "@shopware-pwa/composables-next";

const props = defineProps<{
  content: CmsElementProductSlider;
}>();

const products = computed(() => props.content?.data?.products ?? []);
const appearance = computed(() => props.content?.config?.appearance?.value ?? "slider");
</script>

<template>
  <div>
    <h3 v-if="content?.config?.title" class="mb-6 text-h3 text-maas-typography-headline-primary">{{content?.config?.title.value}}</h3>
    <SharedFeed :products="products" :type="appearance" />
  </div>
</template>
